import React from 'react';
import styled from 'styled-components';
import Ellipse3 from '../../../images/product-assets/Ellipse3.svg';
import { criminalCheckHelpCard } from '../components/utilities';

const Container = styled.section`
    position: relative;
    overflow: hidden;
`;

const ContentBox = styled.div`
    z-index: 1;
    max-width: 1280px;
    margin: 0 auto;
    padding: 100px 20px 0;

    @media screen and (max-width: 500px) {
        padding: 54px 16px 0;
    }
`;

const Heading = styled.h1`
    color: #333;
    font-size: 42px;
    font-weight: 500;
    line-height: 48px;
    text-align: center;

    @media screen and (max-width: 500px) {
        font-size: 24px;
        line-height: 28px;
    }
`;

const GridBox = styled.div`
    margin-top: 72px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 36px;

    @media screen and (max-width: 620px) {
        grid-template-columns: 1fr;
        margin-top: 36px;
    }
`;

const Card = styled.div`
    border: 1px solid #d1d5db;
    padding: 32px;
    border-radius: 20px;
    background-color: #fff;

    @media screen and (max-width: 500px) {
        padding: 32px 20px;
    }
`;

const Icons = styled.img`
    width: 65px;
    height: 65px;

    @media screen and (max-width: 500px) {
        width: 35px;
        height: 35px;
    }
`;

const CardHeading = styled.h3`
    font-size: 32px;
    font-weight: 600;
    line-height: 37.92px;
    color: #0c1a68;

    @media screen and (max-width: 500px) {
        font-size: 22px;
        line-height: 26px;
    }
`;

const CardDesc = styled.p`
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    color: #6b7280;

    @media screen and (max-width: 500px) {
        font-size: 14px;
        line-height: 21px;
    }
`;

const EllipseOne = styled.img`
    position: absolute;
    left: -8%;
    bottom: 25%;
    z-index: -1;

    @media screen and (max-width: 500px) {
        display: none;
    }
`;

const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

function HelpInfoSection() {
    return (
        <Container>
            <ContentBox>
                <Heading>Ways we help you</Heading>
                <GridBox>
                    {criminalCheckHelpCard?.map((card) => (
                        <Card key={card.id}>
                            <CardHeader>
                                <Icons src={card.icon} alt="Card Icon" />
                                <CardHeading>{card.title}</CardHeading>
                            </CardHeader>
                            <CardDesc>{card.desc}</CardDesc>
                        </Card>
                    ))}
                </GridBox>
            </ContentBox>
            <EllipseOne src={Ellipse3} alt="Ellipse 3" />
        </Container>
    );
}

export default HelpInfoSection;
